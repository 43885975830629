.portfolio-settings-name-field{
    width: 250px;
}

.portfolio-settings-input-field{

}

.portfolio-settings-page-container {
    width: 1800px;
    max-width: 100%;
    margin: 0 auto;
    height: 855px;
    padding: 15px;
    box-sizing: border-box;
}

.page-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}