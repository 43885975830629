/* Error Message */
.error-message {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 10px;
    margin-left: 5px;
}

/* Input Fields */
.modal-content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.title {
    font-size: 20px;
}