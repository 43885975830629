.instrument-search-container {
    position: relative;
    width: 100%;
}

.loading-text {
    color: #6b7280; /* Tailwind's text-gray-500 */
    font-size: 0.875rem; /* text-sm */
}

.dropdown-results {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0.25rem; /* mt-1 */
    background-color: white;
    border: 1px solid #d1d5db; /* Tailwind's border-gray-300 */
    border-radius: 0.25rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 16rem; /* max-h-64 */
    overflow-y: auto;
    z-index: 10;
}

.results-table {
    width: 100%;
    font-size: 0.875rem;
    border-collapse: collapse;
}

.results-header {
    background-color: #f3f4f6; /* Tailwind's bg-gray-100 */
    position: sticky;
    top: 0;
}

.result-row {
    cursor: pointer;
}

.result-row:hover {
    background-color: #f9fafb; /* Tailwind's hover:bg-gray-100 */
}

.result-row td {
    padding: 0.5rem;
}
