.search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.search-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #f5f5f5; /* Light grey background */
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.search-icon-container:hover {
    background-color: #e0e0e0; /* Slightly darker grey on hover */
    transform: scale(1.05);
}

.search-icon {
    font-size: 24px;
    color: #007bff;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

.search-icon:hover {
    color: #0056b3;
    transform: scale(1.1);
}
