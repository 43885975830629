.portfolio-search-container {
  width: 400px;
  margin: 1rem auto;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: sans-serif;
}

.portfolio-search-input {
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  background-color: #f1f1f1;
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
}

.portfolio-suggestion-list {
  list-style: none;
  padding: 0;
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
}

.selected-portfolio-display {
  margin-top: 12px;
  padding: 8px 12px;
  background-color: #f0f8ff;
  border-left: 4px solid #007bff;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
}

.portfolio-suggestion-item {
  padding: 10px 12px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease;
}

.portfolio-suggestion-item:hover {
  background-color: #f0f8ff;
}

.portfolio-search-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.portfolio-search-input {
  flex: 1;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  background-color: #f1f1f1;
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
}

.selected-portfolio-inline {
  white-space: nowrap;
  font-size: 14px;
  background-color: #f0f8ff;
  padding: 6px 12px;
  border-left: 4px solid #007bff;
  border-radius: 6px;
  color: #333;
}
