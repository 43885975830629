/* The overlay that will cover the entire screen */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
    backdrop-filter: blur(5px); /* Blurs everything behind the modal */
    z-index: 1000;
}

/* The modal itself */
.modal-container {
    background-color: white;
    width: 100%;
    /*max-width: 1500px;*/
    border-radius: 16px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    /*padding: 20px;*/
    position: relative;
    text-align: center;
    z-index: 1001;
    border: 4px solid #0171d3; /* Kék keret hozzáadása */
    /*box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); !* Árnyék a kiemeléshez *!*/
}

/* Header of Modal */
.modal-header {
    background-color: #f8f9fa;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
}

/* Title */
.modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
}

/* Close Button */
.close-btn {
    font-size: 1.5rem;
    color: #999;
    cursor: pointer;
    transition: color 0.3s;
}

.close-btn:hover {
    color: red;
}

/* Body of Modal */
.modal-body {
    padding: 20px;
}

/* Footer of Modal */
.modal-footer {
    background-color: #f1f1f1;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #ddd;
}

/* Footer Button Styles */
.modal-footer button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modal-footer button:hover {
    background-color: #0056b3;
}

/*.password-label-container {*/
/*    display: flex;*/
/*    align-items: center;  !* Align label and icon horizontally *!*/
/*    gap: 10px;  !* Space between label and icon *!*/
/*}*/
/* Adding a smooth transition animation */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
