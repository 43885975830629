
/*FAF7F5*/
/*D3BEA3*/
/*CAB4A8*/
/*B5A49D*/
/*9D7374*/

body{
    font-family: "Segoe UI", "Roboto", "Helvetica Neue", sans-serif, "Ubuntu";
}

p{
    font-weight: bold;
}

table{
    table-layout: auto;
    width: 100%;
    text-align: left;
    border-radius: 10px;
}

th{
    font-size: 12px;
    padding: 5px 10px;
    white-space:nowrap;
    background-color: white;
    color: #494f54; /* Dark text for contrast */
}

thead{
    height: 50px;
    /*background-color: #f1f1f1; !* Light gray background *!*/
    color: #494f54; /* Dark text for contrast */
    font-weight: bold;
    text-align: left;
    padding: 12px;

}

td{
    border: 1px solid #f1f1f1;
    border-right: none;
    border-left: none;
    padding: 5px 10px;
    font-size: 12px;
    height: 40px;
    white-space:nowrap;

}

tr{
    color: #494f54;

}

.sticky-column {
    position: sticky;
    right: 0;
    background: #fff; /* Ensures the column does not appear transparent */
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.menu-button{
    font-size: 16px;
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    color: #1b1e21;
}

.menu-button:hover{
    background:  #ececec;
    border-radius: 10px;
    text-decoration: none;
    color: #1b1e21;
}

.alert-button{
    width: 40px;
    height: 40px;
    background-color:  #3498db;  /* Background color of the button */
    border: none;               /* Remove any default borders */
    color: white;               /* Icon color */
    display: flex;              /* Use flexbox for centering */
    justify-content: center;    /* Center horizontally */
    align-items: center;        /* Center vertically */
    font-size: 30px;            /* Icon size */
    border-radius: 50%;         /* Make the button round */
    cursor: pointer;            /* Cursor changes to pointer on hover */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

.alert-button:hover {
    background-color: #154360; /* Darken the color on hover */
}

.table-row-all{
    background: white;
}

.table-row-all:hover{
    background:   #f1f1f1;
    border: none;
}

.nav-tabs {
  border-bottom: 0px solid transparent;
}
.nav-tabs .nav-link {
  border: 0px solid transparent;
}

.nav-bar{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1)
}

.nav-links{
    display: flex;
    gap: 15px;
}

.nav-button{
    padding: 5px;
    margin: 5px;
    color: #282c34;
}

.page-container {
    display: flex;
    background: #FAF8F9;
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 0px;
    justify-content: center; /* Centers the .card horizontally */
    align-items: center;
    /*min-height: 100vh;*/
}

h2 {
    margin-bottom: 20px;
}


.edit-icon {
    position: absolute;
    top: 5px;    /* Slight offset to avoid touching edges */
    right: 5px;  /* Slight offset for better spacing */
    background: #F1F2F0;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.edit-icon:hover {
    background: #F1F2F0;
}

.edit-pen {
    color: #D3BEA3;
    font-size: 14px;
}

button {
    width: 100%;
    height: 100%;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    background-color: #f1f1f1;
    color: #494f54;
    padding: 5px;
}

.button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    margin: 5px;
}

button:hover {
    background-color: #494f54;
    color: white;
    border: 1px solid #494f54;
}
button:disabled {
    background: #aaa;
    cursor: not-allowed;
}

.button-group-vertical {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    text-align: left;
}

.selected-button-vertical {
  background-color: #e1e1e1;
    font-weight: bold;
  color: #313131;
}

.icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
}

/* Edit button (blue) */
.edit-button {
    background-color: #007bff;
    color: white;
}

.edit-button:hover {
    background-color: #0056b3;
}

/* Close button (red) */
.close-button {
    background-color: #dc3545;
    color: white;
}

.close-button:hover {
    background-color: green;
}

.plus-minus-buttons{
    border: none;
    border-radius: 4px;
    background: #5a6268;
    color: white;
}

.information-textbox {
    font-size: 12px;
    color: #B4AAA5;
    padding: 20px;
}

select{
    width: 100%;
    padding: 8px 20px;
    margin: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: #2b2b2b;
    background-color: white;
    cursor: pointer;
}

option{
    padding: 5px;
}

input{
    width: 100%;
    padding: 8px 20px;
    margin: 5px;
    /*display: inline-block;*/
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: #2b2b2b;
    cursor: pointer;
}

.multi-select {
    width: 100%;
    padding: 5px 20px;
    /*margin: 8px 0;*/
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: #2b2b2b;
}

label {
    text-align: left;
    display: block;
    padding: 8px;
    margin: 0px;
    font-size: 14px;
    color: #494f54;
    font-weight: bold;
}

.vertical-box {
    display: flex;
    align-items: center;
    gap: 8px;

}

/*Search container CSS*/
.search-container{
    height: 40px;
    /*width: 100%;*/
    padding: 5px;
    /*margin: 15px;*/
    display: flex;
}

.search-container-label-box {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #2b2b2b;

}

.search-container-label-box label{
    color: #2b2b2b;
    font-size: 14px;
    /*font-weight: bold;*/
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  padding: 8px;
  cursor: pointer;
}

ul li:hover {
  background-color: #eee;
}

.tree-view {
    border: 1px solid #ccc; /* Optional */
    padding: 10px;
}

.tree-node-parent {
    font-size: 14px;
    font-weight: bold;
    /*padding: 8px;*/
    /*margin-top: 4px;*/
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
    cursor: pointer;
    border-radius: 10px;
}

.tree-node-child {
    font-weight: normal;
    /*padding: 8px;*/
    /*margin-top: 4px;*/
    background-color: #f9f9f9;
    /*transition: background-color 0.3s ease;*/
    cursor: pointer;
    border-radius: 10px;
}

/*.tree-node-child:hover {*/
/*    background-color: #e6f7ff;*/
/*    border-color: darkslategrey;*/
/*}*/

/*.tree-node-parent:hover {*/
/*    background-color: #e6f7ff;*/
/*    border-color: dimgrey;*/
/*}*/

.message-box {
    border-radius: 5px;
    background:  #f1f1f1;
    margin-top: 10px;
    padding: 10px;
    margin-bottom: 10px;
    height: 100%;
    font-size: 16px;
}

.card{
    border-radius: 8px;
    background: white;
    height: 100%;
    border-radius: 12px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}

.card-body {
    display: flex;
    flex-direction: column;  /* Stack elements vertically */
    justify-content: center; /* Center content vertically */
    align-items: center;     /* Center content horizontally */
    gap: 15px;               /* Add space between elements */
    padding: 10px;           /* Padding inside the body */
    /*margin: 5px;             !* 5px margin around the card body *!*/
    height: calc(100% - 10px);         /* Padding inside the body */
}

.card-header{
    /*border-radius: 10px;*/
    background:   white ;
    border-bottom: none;
    padding: 5px;
    padding-bottom: 15px;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    color: #494f54;
    align-items: center;
}

.icon{
    border: none;
    font-size: 16px;
    font-weight: bold;
    width: 30px;
    height: 30px;
    padding: 5px;
    color: #1f618d;
    align-items: center;
    justify-content: center;
}

.icon:hover{
    border: #1f618d solid 1px;
    width: 30px;
    height: 30px;
    /*color: white;*/
    border-radius: 10px;
    cursor: pointer;
}

.search-bar{
    margin: 10px;
}

.notification-container {
    position: relative;
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
}

.notification-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.notification-icon {
    position: relative;
    font-size: 26px; /* Adjust the icon size as needed */
}

.notification-badge {
    position: absolute;
    top: -5px; /* Adjust position */
    right: -10px; /* Adjust position */
    background-color: red; /* Badge color */
    color: white;
    border-radius: 50%; /* Make it round */
    padding: 5px 10px; /* Padding around the number */
    font-size: 12px; /* Font size for the badge */
    min-width: 20px; /* Minimum width for the badge */
    text-align: center;
    display: inline-block;
    font-weight: bold; /* Make the number bold */
}

.aligned-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
}

.action-button {
    background: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    transition: background 0.2s;
}

.action-button:hover {
    background: #e0e0e0;
}

.edit-button {
    color: #007bff;
}

.add-button {
    color: #28a745;
}

.info-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 5px 0;
}

.info-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 5px 0;
}

.transaction-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}