.input-group {
    display: flex;
    flex-direction: column;
    /*margin-bottom: 15px;*/
}

.input-group-horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.input-group label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.input-group input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.input-value {
    color: #555;
}

.label-container {
    display: flex;
    align-items: center;
    /*margin-bottom: 5px;*/
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}