.tab-container {
    width: 100%;
    margin: auto;
    font-family: Arial, sans-serif;
    background-color: #fff; /* fehér háttér */
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px; /* nagyobb padding */
}

.tab-buttons {
    display: flex;
    justify-content: center; /* középre helyezés */
    border-bottom: 1px solid #ddd;
    padding: 12px;
}

.tab-button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #007bff; /* alap szín a tab címeknek */
    margin: 0 15px; /* egyenlő távolságok */
    padding: 8px 16px;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
}

.tab-button:hover {
    background: rgba(0, 123, 255, 0.1);
}

.tab-button.active {
    background: #007bff; /* téglalap alakú kijelölés */
    color: #fff; /* fehér szöveg aktív állapotban */
    font-weight: bold;
    border-bottom: none;
    border-radius: 8px; /* lekerekített szélek */
}

.tab-content {
    padding: 20px;
}

.tab-panel {
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}