.cover-photo {
  width: 100%;
  height: 300px;
  background-color: lightgray;
  position: relative;
  background-size: cover;
  background-position: center;
}

.cover-photo-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
}

.content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  gap: 20px;
  margin-top: -80px;
  position: relative;
  padding: 0 40px;
}

.left-panel {
  width: 30%;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-panel {
  width: 70%;
  background: white;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.avatar-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.avatar-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid white;
  display: block;
  margin: 0 auto;
  position: relative;
}

.small-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.avatar-upload {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.avatar-info {
    margin-left: 20px;
    gap: 15px;
    display: flex;
    width: 300px;
}

.username {
  text-align: center;
  margin-top: 5px;
}

.user-stats {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.public-profile-btn {
  display: block;
  width: 150px;
  height: 40px;
  line-height: 40px;
  margin: 10px auto;
  padding: 0;
  background-color: #f1f1f1;
  color: #494f54;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.public-profile-btn:hover {
  background-color: #494f54;
  color: white;
  border: 1px solid #494f54;
}

.nav-menu {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 2px solid #ddd;
}

.nav-menu span {
  cursor: pointer;
  padding: 5px 15px;
  font-size: 14px;
  transition: 0.3s;
}

.nav-menu span:hover {
  color: #007bff;
}

.nav-menu .active {
  font-weight: bold;
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.tab-content {
  padding: 10px;
}

.tab-content h3 {
  margin-bottom: 10px;
}

.public-info ul, .private-info ul {
  list-style-type: none;
  padding: 0;
}

.public-info li, .private-info li {
  padding: 5px 0;
  font-size: 14px;
}


.change-password-section {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
  width: 100%;
}

.change-password-section label {
  min-width: 120px;
  text-align: left;
}

.change-password-section button {
    width: 150px;
}

.joined-info {
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.joined-info strong {
    margin-right: 10px;
}

.joined-info span {
    font-size: 1rem;
}

.edit-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.edit-buttons button {
    margin: 0 10px;
}
