.form {
  /*position: absolute;*/
  max-width: 430px;
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  background: #FFF;
}

.form.signup {
  opacity: 0;
  pointer-events: none;
}

.forms.show-signup .form.signup {
  opacity: 1;
  pointer-events: auto;
}

.forms.show-signup .form.login {
  opacity: 0;
  pointer-events: none;
}

form {
  margin-top: 30px;
}

.field {
  position: relative;
  height: 50px;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
}

.field input:focus {
  border-bottom-width: 2px;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #8b8b8b;
  cursor: pointer;
  padding: 5px;
}

.field button {
  color: #fff;
  background-color: #0171d3;
  transition: all 0.3s ease;
  cursor: pointer;
}

.field button:hover {
  background-color: #016dcb;
}

.form-link {
  text-align: center;
  margin-top: 10px;
}

.form-link span,
.form-link a {
  font-size: 14px;
  font-weight: 400;
  color: #232836;
}

.form a {
  color: #0171d3;
  text-decoration: none;
}

.form a:hover {
  text-decoration: underline;
}

.line {
  position: relative;
  height: 1px;
  width: 100%;
  margin: 36px 0;
  background-color: #d4d4d4;
}

.line::before {
  content: 'Or';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFF;
  color: #8b8b8b;
  padding: 0 15px;
}

.media-options a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

a.facebook {
  color: #fff;
  background-color: #4267b2;
}

.facebook-icon {
  height: 28px;
  width: 28px;
  color: #0171d3;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

@media screen and (max-width: 400px) {
  .form {
    padding: 20px 10px;
  }
}

.password-tooltip-container {
    position: relative;
}

.password-tooltip-container .tooltip-container {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    cursor: pointer;
}

/*.error-message {*/
/*    color: red;*/
/*    text-align: center;*/
/*    margin-top: 10px;*/
/*}*/

/*.general-error {*/
/*  color: red;*/
/*  font-size: 14px;*/
/*  margin-top: 10px;*/
/*  margin-bottom: 12px;*/
/*  text-align: center;*/
/*}*/

/*.success-message {*/
/*    color: green;*/
/*    text-align: center;*/
/*    margin-top: 10px;*/
/*}*/
