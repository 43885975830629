.portfolio-trade-routing-container {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Scrollable Table Wrapper */
.portfolio-trade-routing-container > div {
    overflow-y: auto;
    max-height: 620px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: white;
}
