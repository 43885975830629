.trade-row {
    background-color: #fff;
    transition: background 0.3s ease;
    margin: 5px;
}

.trade-row:hover {
    background-color: #f9f9f9;
}

.trade-cell {
    padding: 12px 16px;
    vertical-align: middle;
}

.select-wrapper {
    width: 100%;
}

.styled-select {
    width: 100%;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background: #f7f7f7;
    font-size: 14px;
    transition: border-color 0.3s;
}

.styled-select:focus {
    outline: none;
    border-color: #4f46e5;
    background-color: #fff;
}

.styled-input {
    width: 100%;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background: #f7f7f7;
    font-size: 14px;
    transition: border-color 0.3s;
}

.styled-input:focus {
    outline: none;
    border-color: #4f46e5;
    background-color: #fff;
}

.action-buttons {
    display: flex;
    gap: 8px;
    align-items: center;
}


/*.icon-button:hover {*/
/*    background: #d1d5db;*/
/*    transform: scale(1.05);*/
/*}*/

.icon-button.active {
    background-color: #00a59a;
    color: white;
}

.icon-button.inactive {
    background-color: #ee7d8b;
    color: white;
}

.icon-button.danger {
    background-color: #fecaca;
}

.icon-button.danger:hover {
    background-color: #f87171;
}
