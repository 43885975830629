.grouping-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.group-button {
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s;
}

.group-button.active {
    background-color: #494f54;
    color: white;
    border-color: #494f54;
}
