/* Alap konténer */
.widget-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: sans-serif;
}
.widget-body {
  margin-top: 20px;
}

.widget-title {
  font-size: 20px;
  font-weight: bold;
  color: #494f54;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #d3d3d3;
  border-radius: 8px 8px 0 0;
  text-align: left;
}


.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: min-content;
  gap: 2rem;
  justify-content: center;
}

.card h3 {
  text-align: center;
}

.card-general {
  grid-column: 1;
  grid-row: 1 / span 2;
}

.card-ownership {
  grid-column: 2;
  grid-row: 1 / span 2;
}

.card-performance {
  grid-column: 3;
  grid-row: 1;
  align-self: start;
}

.card-functional {
  grid-column: 3;
  grid-row: 2;
  align-self: start;
}



.portfolio-settings-card {
  width: 450px;
}


/* Mentés gomb */
.save-button {
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.save-button:hover {
  background-color: #0056b3;
}

/* Mobilbarát */
@media (max-width: 600px) {
  .widget-title {
    font-size: 1.5rem;
  }
  .card h3 {
    font-size: 1rem;
  }
}
@media (max-width: 900px) {
  .form-grid {
    grid-template-columns: 1fr; /* mobilon egy oszlop */
  }
}
